import { Link } from "gatsby";
import React, { useEffect, useState } from "react";
import Layout from "../../components/Layout";
import { trackClickEvents } from "../../utils";
import "./cli.scss";
import SEO from "../../components/SEO";

export interface AppProps {}

const App: React.FC<AppProps> = () => {
  const [linkObj, setLinkObj] = useState({
    name: "Unknown",
    url: "#",
  });

  useEffect(() => {
    setLinkObj(getDownloadLinks());
  }, []);

  return (
    <>
      <SEO
        title={"LoginRadius CLI"}
        description={
          "The LoginRadius CLI streamlines your workflow by using simple commands and allowing you to complete the task in a short amount of time."
        }
      />
      <Layout isCLI={true}>
        <section className="bg-dark-01">
          <div className="grid-100 super-hero">
            <div className="title">
              <h1>
                LoginRadius CLI<span>Beta</span>
              </h1>
              <p>
                Manage your LoginRadius Admin Console without leaving the terminal
              </p>
              <p className="codeblockwrap">
                {(linkObj.name === "Mac" || linkObj.name === "Linux") && (
                  <React.Fragment>
                    <span className="codeblock">
                      <div>
                        <span style={{ color: "#fb660a" }}>$</span>
                        {"  "}
                        <span style={{ color: "#FFFFFF" }}>brew</span>{" "}
                        <span style={{ color: "#FFFFFF" }}>tap</span>{" "}
                        <span style={{ color: "#FFFFFF" }}>
                          loginradius/tap
                        </span>
                      </div>

                      <div>
                        <span style={{ color: "#fb660a" }}>$</span>
                        {"  "}
                        <span style={{ color: "#FFFFFF" }}>brew</span>{" "}
                        <span style={{ color: "#FFFFFF" }}>install</span>{" "}
                        <span style={{ color: "#FFFFFF" }}>lr</span>
                      </div>
                    </span>
                    <div className="sep">OR </div>
                  </React.Fragment>
                )}
                <a
                  className={"btn-primary"}
                  onClick={() => trackClickEvents("CLI Download Button")}
                  href={linkObj.url}
                >
                  {`Download for ${linkObj.name}`}
                </a>
              </p>
              <p className="installation-link margin-top--none">
                <a
                  onClick={() => trackClickEvents("CLI README Link")}
                  href="https://github.com/LoginRadius/lr-cli#installation"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  View installation instructions
                </a>
              </p>
            </div>
          </div>
        </section>
        <section className="bg-light-01">
          <div className="grid-100">
            <div className="title">
              <h2>What Does LoginRadius CLI Do?</h2>
              <p>
                The LoginRadius CLI will simplify your flow by just using some
                simple commands to{" "}
                <strong>login, add site, add social, etc.</strong>,
                and enables you to get the job done in very little time without
                leaving the terminal.{" "}
              </p>
            </div>
          </div>

          <div className="grid-33 nutshell  opensource margin-bottom--md">
            <div className="code">
              {/* HTML generated using hilite.me */}
              <div>
                <pre style={{ margin: 0, lineHeight: "125%" }}>
                  <span style={{ color: "#fb660a" }}>$</span>{" "}
                  <span style={{ color: "#fb660a" }}>lr</span>{" "}
                  <span style={{ color: "#FFFFFF" }}>login</span>
                  {"\n"}
                  {"\n"}
                  <span style={{ color: "#FFFFFF" }}>Successfully</span>{" "}
                  <span style={{ color: "#FFFFFF" }}>Authenticated,</span>{" "}
                  {"\n"}
                  <span style={{ color: "#FFFFFF" }}>Fetching</span>{" "}
                  <span style={{ color: "#FFFFFF" }}>Your</span>{" "}
                  <span style={{ color: "#FFFFFF" }}>Site(s)...</span>
                  {"\n"}
                  <span style={{ color: "#0086d2" }}>?</span>{" "}
                  <span style={{ color: "#FFFFFF" }}>Current</span>{" "}
                  <span style={{ color: "#FFFFFF" }}>Site</span>{" "}
                  <span style={{ color: "#FFFFFF" }}>is</span>
                  <span style={{ color: "#0086d2" }}>:</span>{" "}
                  <span style={{ color: "#FFFFFF" }}>dev</span>
                  <span style={{ color: "#0086d2" }}>-</span>
                  <span style={{ color: "#FFFFFF" }}>product</span>
                  <span style={{ color: "#0086d2" }}>-</span>
                  <span style={{ color: "#fb660a" }}>1</span>
                  <span style={{ color: "#FFFFFF" }}>,</span>
                  {"\n"}
                  <span style={{ color: "#FFFFFF" }}>Want</span>{" "}
                  <span style={{ color: "#FFFFFF" }}>to</span>{" "}
                  <span style={{ color: "#FFFFFF" }}>Switch</span>
                  <span style={{ color: "#0086d2" }}>?</span>{" "}
                  <span style={{ color: "#FFFFFF" }}>Yes</span>
                  {"\n"}
                  <span style={{ color: "#0086d2" }}>?</span>{" "}
                  <span style={{ color: "#FFFFFF" }}>Select</span>{" "}
                  <span style={{ color: "#FFFFFF" }}>the</span>{" "}
                  <span style={{ color: "#FFFFFF" }}>site</span>{" "}
                  <span style={{ color: "#FFFFFF" }}>from</span>{" "}
                  <span style={{ color: "#FFFFFF" }}>the</span>{" "}
                  <span style={{ color: "#FFFFFF" }}>list</span>
                  <span style={{ color: "#0086d2" }}>:</span>{" "}
                  <span style={{ color: "#FFFFFF" }}>dev</span>
                  <span style={{ color: "#0086d2" }}>-</span>
                  <span style={{ color: "#FFFFFF" }}>product</span>
                  <span style={{ color: "#0086d2" }}>-</span>
                  <span style={{ color: "#fb660a" }}>2</span>
                  {"\n"}
                  <span style={{ color: "#FFFFFF" }}>Site</span>{" "}
                  <span style={{ color: "#FFFFFF" }}>has</span>{" "}
                  <span style={{ color: "#FFFFFF" }}>been</span>{" "}
                  <span style={{ color: "#FFFFFF" }}>updated.</span>
                  {"\n"}
                  <span style={{ color: "#FFFFFF" }}>Successfully</span>{" "}
                  <span style={{ color: "#FFFFFF" }}>Logged</span>{" "}
                  <span style={{ color: "#FFFFFF" }}>In</span>
                  {"\n"}
                </pre>
              </div>
              <div className="text">
                <p>
                  Use this command to log in to your LoginRadius account. The
                  authentication process uses a web-based browser flow.
                </p>
              </div>
            </div>

            <div>
              <div className="code">
                <pre style={{ margin: 0, lineHeight: "125%" }}>
                  <span style={{ color: "#fb660a" }}>$</span>{" "}
                  <span style={{ color: "#fb660a" }}>lr</span>{" "}
                  <span style={{ color: "#fb660a" }}>get</span>{" "}
                  <span style={{ color: "#FFFFFF" }}>config</span>
                  {"\n"}
                  {"\n"}
                  <span style={{ color: "#FFFFFF" }}>API</span>{" "}
                  <span style={{ color: "#FFFFFF" }}>Key</span>
                  <span style={{ color: "#0086d2" }}>:</span>{" "}
                  <span style={{ color: "#0086d2" }}>&lt;</span>
                  <span style={{ color: "#FFFFFF" }}>Your</span>{" "}
                  <span style={{ color: "#FFFFFF" }}>LoginRadius</span>{" "}
                  <span style={{ color: "#FFFFFF" }}>apikey</span>
                  <span style={{ color: "#0086d2" }}>&gt;</span>
                  {"\n"}
                  <span style={{ color: "#FFFFFF" }}>API</span>{" "}
                  <span style={{ color: "#FFFFFF" }}>Secret</span>
                  <span style={{ color: "#0086d2" }}>:</span>{" "}
                  <span style={{ color: "#0086d2" }}>&lt;</span>
                  <span style={{ color: "#FFFFFF" }}>Your</span>{" "}
                  <span style={{ color: "#FFFFFF" }}>LoginRadius</span>{" "}
                  <span style={{ color: "#FFFFFF" }}>Secret</span>
                  <span style={{ color: "#0086d2" }}>&gt;</span>
                  {"\n"}
                  {"\n"}
                  <span style={{ color: "#fb660a" }}>$</span>{" "}
                  <span style={{ color: "#fb660a" }}>lr</span>{" "}
                  <span style={{ color: "#FFFFFF" }}>reset</span>
                  <span style={{ color: "#0086d2" }}>-</span>
                  <span style={{ color: "#FFFFFF" }}>secret</span> {"\n"}
                  <span style={{ color: "#FFFFFF" }}>API</span>{" "}
                  <span style={{ color: "#FFFFFF" }}>Secret</span>{" "}
                  <span style={{ color: "#FFFFFF" }}>reset</span>{" "}
                  <span style={{ color: "#FFFFFF" }}>successfully</span>
                  {"\n"}
                </pre>
              </div>
              <div className="text">
                <p>
                  To get your LoginRadius API credentials or reset the API
                  Secret, you can use the following commands.
                </p>
              </div>
            </div>
            <div>
              <div className="code">
                <pre style={{ margin: 0, lineHeight: "125%" }}>
                  <span style={{ color: "#fb660a" }}>$</span>{" "}
                  <span style={{ color: "#fb660a" }}>lr</span>{" "}
                  <span style={{ color: "#fb660a" }}>get</span>{" "}
                  <span style={{ color: "#FFFFFF" }}>domain</span>
                  {"\n"}
                  {"\n"}
                  <span style={{ color: "#fb660a" }}>1</span>
                  <span style={{ color: "#FFFFFF" }}>.</span>{" "}
                  <span style={{ color: "#FFFFFF" }}>http</span>
                  <span style={{ color: "#0086d2" }}>:</span>
                  <span style={{ color: "#0086d2" }}>//localhost</span>
                  {"\n"}
                  <span style={{ color: "#fb660a" }}>2</span>
                  <span style={{ color: "#FFFFFF" }}>.</span>{" "}
                  <span style={{ color: "#FFFFFF" }}>http</span>
                  <span style={{ color: "#0086d2" }}>:</span>
                  <span style={{ color: "#0086d2" }}>//127.0.0.1</span>
                  {"\n"}
                  <span style={{ color: "#fb660a" }}>3</span>
                  <span style={{ color: "#FFFFFF" }}>.</span>{" "}
                  <span style={{ color: "#FFFFFF" }}>loginradius.com</span>
                  {"\n"}
                  {"\n"}
                  <span style={{ color: "#fb660a" }}>$</span>{" "}
                  <span style={{ color: "#fb660a" }}>lr</span>{" "}
                  <span style={{ color: "#FFFFFF" }}>add</span>{" "}
                  <span style={{ color: "#FFFFFF" }}>domain</span>{" "}
                  <span style={{ color: "#fb660a" }}>-</span>
                  <span style={{ color: "#fb660a" }}>d</span>{" "}
                  <span style={{ color: "#FFFFFF" }}>google.com</span> {"\n"}
                  <span style={{ color: "#FFFFFF" }}>google.com</span>{" "}
                  <span style={{ color: "#FFFFFF" }}>is</span>{" "}
                  <span style={{ color: "#FFFFFF" }}>now</span>{" "}
                  <span style={{ color: "#FFFFFF" }}>whitelisted.</span>
                  {"\n"}
                </pre>
              </div>
              <div className="text">
                <p>
                  For the management of your whitelisted domain, you can use the
                  following commands.
                </p>
              </div>
            </div>
          </div>
          <div className="grid-33 nutshell  opensource margin-bottom--lg">
            <div>
              <div className="code">
                <pre style={{ margin: 0, lineHeight: "125%" }}>
                  <span style={{ color: "#fb660a" }}>$</span>{" "}
                  <span style={{ color: "#fb660a" }}>lr</span>{" "}
                  <span style={{ color: "#fb660a" }}>add</span>{" "}
                  <span style={{ color: "#FFFFFF" }}>social</span>
                  {"\n"}
                  {"\n"}
                  <span style={{ color: "#0086d2" }}>?</span>{" "}
                  <span style={{ color: "#FFFFFF" }}>Select</span>{" "}
                  <span style={{ color: "#FFFFFF" }}>the</span>{" "}
                  <span style={{ color: "#FFFFFF" }}>provider</span>{" "}
                  <span style={{ color: "#FFFFFF" }}>from</span>{" "}
                  <span style={{ color: "#FFFFFF" }}>the</span>{" "}
                  <span style={{ color: "#FFFFFF" }}>list</span>
                  <span style={{ color: "#0086d2" }}>:</span>{" "}
                  <span style={{ color: "#FFFFFF" }}>Google</span>
                  {"\n"}
                  <span style={{ color: "#FFFFFF" }}>Please</span>{" "}
                  <span style={{ color: "#FFFFFF" }}>enter</span>{" "}
                  <span style={{ color: "#FFFFFF" }}>the</span>{" "}
                  <span style={{ color: "#FFFFFF" }}>provider</span>{" "}
                  <span style={{ color: "#FFFFFF" }}>key</span>
                  <span style={{ color: "#0086d2" }}>:</span>
                  {"\n"}
                  <span style={{ color: "#0086d2" }}>********</span>
                  {"\n"}
                  <span style={{ color: "#FFFFFF" }}>Please</span>{" "}
                  <span style={{ color: "#FFFFFF" }}>enter</span>{" "}
                  <span style={{ color: "#FFFFFF" }}>the</span>{" "}
                  <span style={{ color: "#FFFFFF" }}>provider</span>{" "}
                  <span style={{ color: "#FFFFFF" }}>secret</span>
                  <span style={{ color: "#0086d2" }}>:</span>
                  {"\n"}
                  <span style={{ color: "#0086d2" }}>********</span>
                  {"\n"}
                  <span style={{ color: "#FFFFFF" }}>Social</span>{" "}
                  <span style={{ color: "#FFFFFF" }}>Provider</span>{" "}
                  <span style={{ color: "#FFFFFF" }}>added</span>{" "}
                  <span style={{ color: "#FFFFFF" }}>successfully</span>
                  {"\n"}
                </pre>
              </div>
              <div className="text">
                <p>
                  You can configure the different login methods for your
                  LoginRadius Admin Console using the following commands.
                </p>
              </div>
            </div>
            <div>
              <div className="code">
                <pre style={{ margin: 0, lineHeight: "125%" }}>
                  <span style={{ color: "#fb660a" }}>$</span>{" "}
                  <span style={{ color: "#fb660a" }}>lr</span>{" "}
                  <span style={{ color: "#fb660a" }}>set</span>{" "}
                  <span style={{ color: "#FFFFFF" }}>theme</span>{" "}
                  <span style={{ color: "#0086d2" }}>-</span>
                  <span style={{ color: "#FFFFFF" }}>t</span>{" "}
                  <span style={{ color: "#FFFFFF" }}>London</span>
                  {"\n"}
                  {"\n"}
                  <span style={{ color: "#0086d2" }}>?</span>{" "}
                  <span style={{ color: "#FFFFFF" }}>Previous</span>{" "}
                  <span style={{ color: "#FFFFFF" }}>changes</span>{" "}
                  <span style={{ color: "#FFFFFF" }}>will</span>{" "}
                  <span style={{ color: "#FFFFFF" }}>be</span>{" "}
                  <span style={{ color: "#FFFFFF" }}>lost.</span>
                  {"\n"}
                  <span style={{ color: "#FFFFFF" }}>Do</span>{" "}
                  <span style={{ color: "#FFFFFF" }}>you</span>{" "}
                  <span style={{ color: "#FFFFFF" }}>wish</span>{" "}
                  <span style={{ color: "#FFFFFF" }}>to</span>{" "}
                  <span style={{ color: "#fb660a" }}>continue</span>
                  <span style={{ color: "#0086d2" }}>?</span>{" "}
                  <span style={{ color: "#FFFFFF" }}>Yes</span>
                  {"\n"}
                  <span style={{ color: "#FFFFFF" }}>Reseting</span>{" "}
                  <span style={{ color: "#FFFFFF" }}>current</span>{" "}
                  <span style={{ color: "#FFFFFF" }}>theme...</span>
                  {"\n"}
                  <span style={{ color: "#FFFFFF" }}>Fetching</span>{" "}
                  <span style={{ color: "#FFFFFF" }}>Auth</span>{" "}
                  <span style={{ color: "#FFFFFF" }}>theme</span>{" "}
                  <span style={{ color: "#FFFFFF" }}>url...</span>
                  {"\n"}
                  <span style={{ color: "#FFFFFF" }}>Updating</span>{" "}
                  <span style={{ color: "#FFFFFF" }}>CSS</span>{" "}
                  <span style={{ color: "#FFFFFF" }}>based</span>{" "}
                  <span style={{ color: "#FFFFFF" }}>on</span>{" "}
                  <span style={{ color: "#fb660a" }}>new</span>{" "}
                  <span style={{ color: "#FFFFFF" }}>theme...</span>
                  {"\n"}
                  <span style={{ color: "#FFFFFF" }}>Fetching</span>{" "}
                  <span style={{ color: "#FFFFFF" }}>data...</span>
                  {"\n"}
                  <span style={{ color: "#FFFFFF" }}>Updating</span>{" "}
                  <span style={{ color: "#FFFFFF" }}>to</span>{" "}
                  <span style={{ color: "#fb660a" }}>new</span>{" "}
                  <span style={{ color: "#FFFFFF" }}>theme...</span>
                  {"\n"}
                  <span style={{ color: "#FFFFFF" }}>Your</span>{" "}
                  <span style={{ color: "#FFFFFF" }}>theme</span>{" "}
                  <span style={{ color: "#FFFFFF" }}>has</span>{" "}
                  <span style={{ color: "#FFFFFF" }}>been</span>{" "}
                  <span style={{ color: "#FFFFFF" }}>changed</span>
                  {"\n"}
                </pre>
              </div>
              <div className="text">
                <p>
                  You can update the LoginRadius IDX Page theme from the
                  predefined themes available through LoginRadius CLI.
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="bg-light-03 footer-cta">
          <div className="grid-100">
            <div className="title">
              <h2>Further Documentation</h2>
              <p>
                Find out the documentation for all the commands supported in
                LoginRadius CLI.
              </p>
              <Link to={"https://www.loginradius.com/docs/api/v2/announcements/cli/"} className="btn-primary link-wrap">
                {"Our Docs"}
              </Link>
            </div>
          </div>
        </section>
      </Layout>
    </>
  );
};

const getDownloadLinks = () => {
  if (window.navigator.appVersion.indexOf("Win") !== -1) {
    return {
      name: "Windows",
      url: "https://github.com/LoginRadius/lr-cli/releases/download/v2.0.1-beta/lr_2.0.1-beta_windows_amd64.zip",
    };
  }
  if (window.navigator.appVersion.indexOf("Mac") !== -1) {
    return {
      name: "Mac",
      url: "https://github.com/LoginRadius/lr-cli/releases/download/v2.0.1-beta/lr_2.0.1-beta_macOS_amd64.tar.gz",
    };
  }
  if (window.navigator.appVersion.indexOf("Linux") !== -1) {
    return {
      name: "Linux",
      url: "https://github.com/LoginRadius/lr-cli/releases/download/v2.0.1-beta/lr_2.0.1-beta_linux_amd64.tar.gz",
    };
  }
  return {
    name: "Unknown",
    url: "https://github.com/LoginRadius/lr-cli/releases/tag/v2.0.1-beta",
  };
};

export default App;
